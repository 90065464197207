<style lang='postcss'>
</style>

<template>
  <div class="flex justify-center items-center flex-grow flex-col">
    <h1 class="game-title-2 mb-5">Страница не найдена</h1>
    <router-link :to="{name: 'Stages'}" class="sb-btn">Перейти в игру</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
