<template>
  <svg
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49125 2.8C3.49125 2.63431 3.62556 2.5 3.79125 2.5L13.7 2.5C13.8657 2.5 14 2.36569 14 2.2V1.8C14 1.63431 13.8657 1.5 13.7 1.5L3.79125 1.5C3.62556 1.5 3.49125 1.36569 3.49125 1.2V0.517597C3.49125 0.287106 3.24213 0.142714 3.04213 0.257285L0.454407 1.73969C0.253237 1.85493 0.253238 2.14507 0.454407 2.26031L3.04213 3.74272C3.24213 3.85729 3.49125 3.71289 3.49125 3.4824V2.8Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCheckCircle',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {}
};
</script>
