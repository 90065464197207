<style lang='postcss'>
.intention {
  @apply md:px-8 mt-6 flex flex-col md:flex-row justify-between md:items-center text-left;
  color: #ffffff;
  &__title {
    @apply font-sb_sans_caps mb-6 md:mb-0;
    font-size: 24px;
    line-height: 120%;
  }
}
</style>

<template>
  <div class="intention">
    <p class="intention__title">Хочешь работать в Сбере?</p>
    <div>
      <button class="sb-btn mr-5" @click="openPhoneModal">Да</button>
      <button class="sb-btn sb-btn--outline" @click="sendAnswer('negative')">
        Нет
      </button>
    </div>
  </div>
</template>

<script>
import ModalntentionPhone from "./ModalntentionPhone.vue";
import axios from 'axios';

export default {
  name: "SberIntention",
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    openPhoneModal() {
      this.sendAnswer("positive");
      this.$modal.show(
        ModalntentionPhone,
        {},
        {
          name: "phone-modal",
          classes: "phone-modal",
          height: "auto",
          width: document.documentElement.clientWidth > 700 ? "636px" : "100%",
          clickToClose: true,
          scrollable: true,
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    async sendAnswer(val) {
      await axios
        .post("/set-intention", { intention: val })
        .then((resp) => {
          if (resp.status === 200) {
            this.$store.dispatch("game/setMarquee", {
              text: "Ответ отправлен",
              type: "success",
            });
            if (val === "negative") {
              this.$store.dispatch("game/hideIntention");
            }
          }
        })
        .catch((e) => {
          this.$store.dispatch("game/setMarquee", {
            text: "Сервер не отвечает, что-то сломалось",
            type: "error",
          });
        })
        .finally();
    },
  },
  computed: {},
};
</script>
