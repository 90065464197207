<template>
  <svg
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M37.5 21C37.5 29.8364 30.3364 37 21.5 37C12.6636 37 5.5 29.8364 5.5 21C5.5 12.1636 12.6636 5 21.5 5C30.3364 5 37.5 12.1636 37.5 21Z"
        fill="#FFCC4D"
      />
      <path
        d="M25.0552 29.0001C25.0552 31.4561 23.4632 31.6668 21.4996 31.6668C19.5352 31.6668 17.9441 31.4561 17.9441 29.0001C17.9441 26.5459 19.5352 23.6668 21.4996 23.6668C23.4632 23.6668 25.0552 26.5459 25.0552 29.0001ZM32.1663 18.3335C32.0561 18.3335 31.9441 18.313 31.8365 18.2695C27.1885 16.4108 25.2836 13.6108 25.2045 13.4926C24.9316 13.0837 25.0418 12.5317 25.4507 12.2597C25.8587 11.9877 26.4081 12.0961 26.6818 12.5032C26.6987 12.5272 28.3974 14.9788 32.4961 16.6179C32.9521 16.801 33.1743 17.3175 32.9921 17.7735C32.8525 18.1219 32.5183 18.3335 32.1663 18.3335ZM10.8329 18.3335C10.4801 18.3335 10.1467 18.1219 10.0072 17.7744C9.82494 17.3184 10.0463 16.801 10.5023 16.6188C14.6018 14.9797 16.3005 12.5281 16.3174 12.5041C16.5912 12.0988 17.1432 11.9912 17.5503 12.2641C17.9565 12.5379 18.0667 13.0864 17.7947 13.4935C17.7156 13.6117 15.8107 16.4117 11.1636 18.2703C11.0552 18.313 10.9432 18.3335 10.8329 18.3335Z"
        fill="#664500"
      />
      <path
        d="M26.8336 19.2222H30.3891V36.1111L26.8336 36.0702V19.2222ZM12.6113 36.1111L16.1669 36.0702V19.2222H12.6113V36.1111Z"
        fill="#5DADEC"
      />
      <path
        d="M18.832 21.0002C18.6986 21.0002 18.5626 20.97 18.4355 20.9069C15.3138 19.346 12.1511 20.8909 12.12 20.9069C11.68 21.1282 11.1466 20.9478 10.9271 20.5096C10.7075 20.0704 10.8853 19.5362 11.3244 19.3167C11.4862 19.2367 15.3226 17.3638 19.2311 19.3167C19.6702 19.5362 19.848 20.0704 19.6284 20.5096C19.472 20.8207 19.1582 21.0002 18.832 21.0002ZM31.2764 21.0002C31.1431 21.0002 31.0071 20.97 30.88 20.9069C27.7573 19.346 24.5955 20.8909 24.5644 20.9069C24.1253 21.1273 23.592 20.9487 23.3715 20.5096C23.152 20.0704 23.3298 19.5362 23.7689 19.3167C23.9306 19.2367 27.7698 17.3647 31.6755 19.3167C32.1146 19.5362 32.2924 20.0704 32.0729 20.5096C31.9164 20.8207 31.6026 21.0002 31.2764 21.0002Z"
        fill="#664500"
      />
      <path
        d="M21.5 36.9999C30.3366 36.9999 37.5 36.204 37.5 35.2221C37.5 34.2403 30.3366 33.4443 21.5 33.4443C12.6634 33.4443 5.5 34.2403 5.5 35.2221C5.5 36.204 12.6634 36.9999 21.5 36.9999Z"
        fill="#5DADEC"
      />
      <path
        d="M21.4997 30.7777C22.9724 30.7777 24.1663 29.9818 24.1663 28.9999C24.1663 28.0181 22.9724 27.2222 21.4997 27.2222C20.0269 27.2222 18.833 28.0181 18.833 28.9999C18.833 29.9818 20.0269 30.7777 21.4997 30.7777Z"
        fill="#E75A70"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(5.5 5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSmileRating1',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
