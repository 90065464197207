<style lang="postcss">
.login-btn-top {
  @apply md:block hidden mt-12;
}
.login-btn-bottom.sb-btn {
  @apply md:hidden flex mt-10;
}
.start-title {
  @apply font-sb_sans_caps mb-5;
  font-size: 20px;
  line-height: 110%;
  color: rgba(255, 255, 255, 0.8);
}
</style>

<template>
  <level-block-base
    :complete="isGameFinished"
    :level="2"
    :title="isGameFinished ? '' : '5 минут на пароль для крипто-инвестора'"
  >
    <template #left>
      <div v-if="!currentUser" class="login-btn-top">
        <div class="start-title">Сможешь?</div>
        <login-button />
      </div>
      <router-link
        v-else-if="!isGameFinished"
        class="sb-btn mt-10"
        :to="{ name: 'Game' }"
        >{{ gameResult ? 'Продолжить' : 'Играть' }}</router-link
      >
      <div v-if="isGameFinished && gameResult" class="mt-6">
        <p class="text-accent mb-3">
          Поделись результатом<br />и игрой с другом
        </p>
        <copy-link :link="shareLink" class="mt-5" />
      </div>
    </template>
    <!-- <template #right>
      <div class="block-highlight">
        <div class="flex justify-between">
          <div v-if="isGameFinished && currentUser" class="game-position">
            <digital-time-value :value="digitalResult" />
            <span v-if="gameResult.position" class="ml-4"
              >{{ gameResult.position }} место</span
            >
          </div>
          <div v-else class="level-block__title-secondary mb-9">
            Призы для лучших
          </div>
        </div>

        <links-main />
        <login-button v-if="!currentUser" class="login-btn-bottom" />
      </div>
    </template> -->
  </level-block-base>
</template>

<script>
import LevelBlockBase from './LevelBlockBase.vue';
import LoginButton from '@/components/LoginButton.vue';
import GameTimer from '@/components/UI/GameTimer.vue';
import CopyLink from '@/components/CopyLink.vue';
import LinksMain from './LinksMain.vue';
import DigitalTimeValue from './UI/DigitalTimeValue.vue';
import { getDigitalTime } from '../utils/helpers';
import { CONF_SETTINGS } from '@/confSettings';
import Prize from './UI/Prize.vue';

export default {
  name: 'LevelBlockSecond',
  components: {
    LevelBlockBase,
    LoginButton,
    GameTimer,
    CopyLink,
    LinksMain,
    DigitalTimeValue,
    Prize,
  },
  data() {
    return {
      prizes: CONF_SETTINGS.prizes,
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    gameResult() {
      return this.$store.state.user.currentUserGame;
    },
    shareLink() {
      return `${window.location.host}/results/${this.gameResult.hash}`;
    },
    digitalResult() {
      return getDigitalTime(this.gameResult.duration);
    },
    isGameFinished() {
      return this.$store.getters['user/isGameFinished'];
    },
  },
};
</script>
