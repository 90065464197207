<template>
  <svg
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M21.5 37C30.3366 37 37.5 29.8366 37.5 21C37.5 12.1634 30.3366 5 21.5 5C12.6634 5 5.5 12.1634 5.5 21C5.5 29.8366 12.6634 37 21.5 37Z"
        fill="#FFCC4D"
      />
      <path
        d="M21.5004 23.6665C18.28 23.6665 16.1431 23.2914 13.5004 22.7776C12.8969 22.6612 11.7227 22.7776 11.7227 24.5554C11.7227 28.111 15.8071 32.5554 21.5004 32.5554C27.1929 32.5554 31.2782 28.111 31.2782 24.5554C31.2782 22.7776 30.104 22.6603 29.5004 22.7776C26.8578 23.2914 24.7209 23.6665 21.5004 23.6665Z"
        fill="#664500"
      />
      <path
        d="M13.5 24.5557C13.5 24.5557 16.1667 25.4446 21.5 25.4446C26.8333 25.4446 29.5 24.5557 29.5 24.5557C29.5 24.5557 27.7222 28.1112 21.5 28.1112C15.2778 28.1112 13.5 24.5557 13.5 24.5557Z"
        fill="white"
      />
      <path
        d="M16.1666 20.1109C17.3939 20.1109 18.3888 18.718 18.3888 16.9998C18.3888 15.2816 17.3939 13.8887 16.1666 13.8887C14.9393 13.8887 13.9443 15.2816 13.9443 16.9998C13.9443 18.718 14.9393 20.1109 16.1666 20.1109Z"
        fill="#664500"
      />
      <path
        d="M26.8336 20.1109C28.0609 20.1109 29.0558 18.718 29.0558 16.9998C29.0558 15.2816 28.0609 13.8887 26.8336 13.8887C25.6063 13.8887 24.6113 15.2816 24.6113 16.9998C24.6113 18.718 25.6063 20.1109 26.8336 20.1109Z"
        fill="#664500"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(5.5 5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSmileRating4',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
