<style lang='postcss'>
.phone-modal {
  @apply rounded-lg py-8 pl-8 pr-26;
  background: #000000;
  &__content {
    @apply font-sb_sans_caps;
    font-size: 32px;
    line-height: 110%;
    color: #ffffff;
    & input {
      @apply bg-transparent font-chava px-5 block w-full mt-8 mb-16;
      border: 2px solid #ffffff;
      border-radius: 4px;
      font-size: 20px;
      line-height: 140%;
      height: 63px;
      &:focus {
        outline: none;
      }
    }
  }
}
</style>

<template>
  <div class="phone-modal__content">
    <button
      class="sb-btn--icon absolute top-21px right-19px"
      @click="$emit('close')"
    >
      <icon-cross />
    </button>
    <p>Оставь телефон, чтобы с тобой могли связаться</p>
    <input v-model="phone" type="tel" />
    <button class="sb-btn" @click="sendPhone">Отправить</button>
  </div>
</template>

<script>
import IconCross from "./Icons/IconCross.vue";
import axios from 'axios';

export default {
  name: "ModalIntentionPhone",
  components: { IconCross },
  data() {
    return {
      phone: "+7",
    };
  },
  props: {},
  created() {},
  mounted() {},
  methods: {
    async sendPhone() {
      await axios
        .post("/users/current/set-phone", { phone: this.phone })
        .then((resp) => {
          if (resp.status === 200) {
            this.$store.dispatch("game/setMarquee", {
              text: "Мы получили номер, с вами свяжутся",
              type: "success",
            });
            this.$store.dispatch("game/hideIntention");
          }
        })
        .catch((e) => {
          this.$store.dispatch("game/setMarquee", {
            text: e.response?.data?.error
              ? e.response.data.error
              : "Сервер не отвечает, что-то сломалось",
            type: "error",
          });
        })
        .finally(this.$emit("close"));
    },
  },
  computed: {},
};
</script>
