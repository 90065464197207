<style lang="scss"></style>

<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.765 2.09663C14.525 1.89311 14.1481 1.86399 13.7583 2.02047H13.7577C13.3478 2.18495 2.15416 6.98623 1.69848 7.18239C1.6156 7.21119 0.891756 7.48127 0.966316 8.08287C1.03288 8.62527 1.61464 8.84991 1.68568 8.87583L4.53144 9.85023C4.72024 10.4787 5.41624 12.7974 5.57016 13.2928C5.66616 13.6016 5.82264 14.0074 6.09688 14.0909C6.33752 14.1837 6.57688 14.0989 6.73176 13.9773L8.4716 12.3635L11.2802 14.5539L11.3471 14.5939C11.5378 14.6784 11.7206 14.7206 11.895 14.7206C12.0297 14.7206 12.159 14.6954 12.2825 14.6448C12.7033 14.472 12.8716 14.071 12.8892 14.0256L14.9871 3.12095C15.1151 2.53855 14.9372 2.24223 14.765 2.09663ZM7.0412 10.24L6.0812 12.8L5.1212 9.59999L12.4812 4.15999L7.0412 10.24Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTelegram',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
