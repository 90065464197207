<style lang="scss"></style>

<template>
  <div class="prize-item">
    <div class="prize-item__place">{{ place }}</div>
    <div class="prize-item__title">{{ title }}</div>
    <img v-if="image" class="prize-item__image" :srcset="`${image} 2x`" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Prize',
  components: {},
  data() {
    return {};
  },
  props: {
    place: { type: String, default: 'define place' },
    title: { type: String, default: 'define title' },
    image: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
