<style lang="postcss" scoped>
p {
  color: #e0e0e0;
}
.top-page {
  position: relative;
  h1 {
    @apply font-sb_sans_caps;
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
  }
  min-height: 100vh;
  box-sizing: border-box;
  background: linear-gradient(
      35.66deg,
      #090812 37.95%,
      rgba(29, 29, 30, 0.19) 98.88%
    ),
    #090812;
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  &__content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 114px 72px 210px 72px;
    position: relative;
  }
  &__footer {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 64px;
    background: linear-gradient(0deg, #19192f, #19192f),
      linear-gradient(0deg, #0c0c0c, #0c0c0c),
      linear-gradient(0deg, #f4f4f4, #f4f4f4);
    &-main {
      max-width: 1280px;
      margin: 0 auto;
      --indent: 72px;
      padding-left: var(--indent);
      padding-right: var(--indent);
    }
  }
  &__logo-container {
    @apply absolute flex items-center;
    left: 72px;
    top: 32px;
  }
  &__conf-logo {
    display: inline-block;
    vertical-align: middle;
  }
  &__sber-logo {
    background: url('/images/sber-landing-logo.svg');
    width: 104px;
    height: 29px;
    margin-right: 40px;
    display: inline-block;
    vertical-align: top;
  }
  &__back {
    @apply font-sb_sans_text;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.04em;
    display: flex;
    align-items: center;
    color: #7f7e8a;
    margin-left: 0;
    margin-right: auto;
    position: relative;

    &-icon {
      width: 20px;
      height: 8px;
      margin-right: 6px;
    }

    &:hover {
      color: #2b9a4a;
    }

    &:active {
      opacity: 0.7;
    }
  }
  &__tab {
    &-container {
      margin-top: 32px;
      width: max-content;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      margin-bottom: 56px;
    }
    &-item {
      font-family: 'BlenderPro Bold';
      border: none;
      background: transparent;
      font-size: 24px;
      line-height: 100%;
      text-align: center;
      color: rgba(255, 255, 255, 0.5);
      margin: 0 16px;
      display: inline-block;
      padding-bottom: 9px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: #ffffff;
        border-bottom: 1px solid #6dedfc;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__table-container {
    text-align-last: initial;
    vertical-align: top;
    width: 58%;
    display: inline-block;
  }
  &__prizes-container {
    text-align-last: initial;
    width: 23%;
    min-width: 297px;
    display: inline-block;
    vertical-align: top;
    & p {
      font-size: 16px;
      line-height: 120%;
      color: #aeadb1;
      & a {
        color: #ffffff;
        font-size: 16px;
        line-height: 120%;
      }
    }
  }
  &__prize {
    border: 2px solid #aeadb1;
    padding: 24px 10px 24px 31px;
    --clip-size: 20px;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - var(--clip-size)),
      calc(100% - var(--clip-size)) 100%,
      0 100%
    );
    position: relative;
    margin-top: 26px;
    &:last-of-type {
      margin-top: 32px;
      margin-bottom: 24px;
    }
    &:before {
      position: absolute;
      content: '';
      border-bottom: var(--clip-size) solid #aeadb1;
      border-left: var(--clip-size) solid transparent;
      bottom: 0;
      right: 0;
    }
    &-title {
      font-family: 'BlenderPro Bold';
      font-size: 32px;
      line-height: 120%;
      color: #ffffff;
    }
    &-subtitle {
      padding-top: 8px;
      font-size: 18px;
      line-height: 24px;
      font-family: 'BlenderPro';
      color: rgba(255, 255, 255, 0.9);
      & a {
        //text-decoration: underline;
        color: rgba(255, 255, 255);
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.9);
          transition: 0.25s;
        }
        &:hover {
          color: var(--secondary);
          &:after {
            background: var(--secondary);
          }
        }
      }
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__timer {
    font-family: 'BlenderPro Bold';
    font-size: 32px;
    line-height: 120%;
    color: #ffffff;
  }
  &__date-tab {
    font-family: 'BlenderPro Bold';
    font-size: 18px;
    line-height: 100%;
    height: 46px;
    color: rgba(255, 255, 255, 0.5);
    padding: 22px 13px 0 13px;
    border: none;
    background: transparent;
    display: inline-block;
    margin: 0 8px;
    position: relative;

    &.activeTab {
      background: rgba(85, 81, 114, 0.5);
      border-radius: 4px;
      color: #ffffff;
    }
    &:after {
      position: absolute;
      top: 6px;
      left: 13px;
      font-size: 12px;
      line-height: 100%;
      color: rgba(255, 255, 255, 0.6);
      content: 'Не начался';
    }
    &.active {
      &:after {
        content: 'Сейчас идет';
        color: #ffef40;
      }
    }
    &.complete {
      &:after {
        content: 'Завершено';
        color: rgba(255, 255, 255, 0.6);
      }
    }
    &:focus {
      outline: none;
    }
    &-container {
      margin-bottom: 24px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  &__table-status {
    min-height: 73px;
    line-height: 100%;
    opacity: 0.8;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-family: 'BlenderPro Bold';
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    padding: 28px 32px 28px 32px;
    & span {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 12px);
        left: -32px;
        width: 16px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 7V5C13 3.67392 12.4732 2.40215 11.5355 1.46447C10.5979 0.526784 9.32608 0 8 0C6.67392 0 5.40215 0.526784 4.46447 1.46447C3.52678 2.40215 3 3.67392 3 5V7C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V10C16 9.20435 15.6839 8.44129 15.1213 7.87868C14.5587 7.31607 13.7956 7 13 7ZM5 5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2C8.79565 2 9.55871 2.31607 10.1213 2.87868C10.6839 3.44129 11 4.20435 11 5V7H5V5ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H13C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V17Z' fill='white' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
.button-bot {
  --size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  background-color: #4EEA8C;
  border-radius: 50%;
  color: #0d0b14;

  &:hover {
    background-color: #0f6a28;
    color: white;
  }
}
@media (max-width: 992px) {
  .top-page {
    &__table-container {
      width: 108%;
    }
    &__prizes-container {
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .top-page {
    &__prize {
      display: inline-block;
      width: 48%;
      &:first-of-type {
        margin-right: 4%;
      }
    }
  }
}

@media (max-width: 768px) {
  .top-page {
    h1 {
      font-size: 24px;
      line-height: 28px;
    }
    &__logo-container {
      left: 8px;
      top: 16px;
    }
    &__content {
      padding: 86px 8px 24px 8px;
    }
    &__tab {
      &-container {
        margin-top: 24px;
        margin-bottom: 24px;
        overflow: auto;
        max-width: 100%;
        white-space: nowrap;
      }
      &-item {
        font-size: 16px;
      }
    }
    &__date-tab {
      padding: 22px 8px 8px 8px;
      &-container {
        margin-bottom: 9px;
        overflow: auto;
        max-width: 100%;
        white-space: nowrap;
      }
    }
    &__table-container {
      margin-bottom: 24px;
    }
    &__prize {
      width: 100%;
    }
    &__footer {
      width: 100%;
      position: initial;
      &-main {
        --indent: 8px;
      }
    }
  }
}
</style>

<template>
  <div class="top-page">
    <div class="top-page__content">
      <div class="top-page__head">
        <button class="top-page__back" @click="$router.go(-1)">
          <IconArrowBack class="top-page__back-icon" />
          Назад
        </button>
        <a href="https://hmsg.me/v18713" class="button-bot" target="_blank" @click="track">
          <IconTelegram />
        </a>
      </div>

      <h1>Вакансии сбера</h1>
      <p
        class="font-sb_sans_text mx-auto"
        style="max-width: 600px; margin-top: 30px; margin-bottom: 30px"
      >
        Сбер находится в постоянном поиске талантливых специалистов из разных
        областей. Посмотри кого мы ищем сейчас
      </p>
      <list-vacancies />
    </div>
  </div>
</template>

<script>
import ListVacancies from '@/components/ListVacancies.vue';
import IconArrowBack from '@/components/Icons/IconArrowBack.vue';
import IconTelegram from '@/components/Icons/IconTelegram.vue';

export default {
  name: 'JobPage',
  components: {
    ListVacancies,
    IconArrowBack,
    IconTelegram
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    track() {
      if (window.ym) {
        window.ym(84425707,'reachGoal','clicked-on-telegram-bot')
      }
    }
  }
};
</script>
