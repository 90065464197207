<style lang="postcss">
.results {
  @apply mx-auto w-full md:w-758px my-6 md:my-20;
  & .level-block:last-child {
    @apply mb-0;
  }
}
</style>

<template>
  <div class="results">
    <level-block-first class="animate fadeInDown one" />
    <level-block-second class="animate fadeInUp two" />
    <feedback-block v-if="currentUser" ref="third-block" class="animate fadeInUp three" />
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div v-if="currentUser && showIntention">
        <div class="divider" />
        <sber-intention />
      </div>
    </transition>
  </div>
</template>

<script>
import LevelBlockFirst from "@/components/LevelBlockFirst.vue";
import LevelBlockSecond from "@/components/LevelBlockSecond.vue";
import SberIntention from "@/components/SberIntention.vue";
import FeedbackBlock from '../../components/FeedbackBlock.vue';

export default {
  components: {
    LevelBlockFirst,
    LevelBlockSecond,
    SberIntention,
    FeedbackBlock,
  },
  name: "StagesPage",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    if (this.isGameFinished) {
      setTimeout(() => {
        this.$refs["third-block"].$el.scrollIntoView();
      }, 3500);
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.user.currentUser;
    },
    showIntention() {
      return this.$store.state.game.showIntention;
    },
    isGameFinished() {
      return this.$store.getters["user/isGameFinished"];
    },
  },
};
</script>
