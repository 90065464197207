<template>
  <svg
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M37.5 21C37.5 29.8364 30.3364 37 21.5 37C12.6636 37 5.5 29.8364 5.5 21C5.5 12.1636 12.6636 5 21.5 5C30.3364 5 37.5 12.1636 37.5 21Z"
        fill="#FFCC4D"
      />
      <path
        d="M21.5004 23.6665C18.28 23.6665 16.1431 23.2914 13.5004 22.7776C12.8969 22.6612 11.7227 22.7776 11.7227 24.5554C11.7227 28.111 15.8071 32.5554 21.5004 32.5554C27.1929 32.5554 31.2782 28.111 31.2782 24.5554C31.2782 22.7776 30.104 22.6603 29.5004 22.7776C26.8578 23.2914 24.7209 23.6665 21.5004 23.6665Z"
        fill="#664500"
      />
      <path
        d="M13.5 24.5557C13.5 24.5557 16.1667 25.4446 21.5 25.4446C26.8333 25.4446 29.5 24.5557 29.5 24.5557C29.5 24.5557 27.7222 28.1112 21.5 28.1112C15.2778 28.1112 13.5 24.5557 13.5 24.5557Z"
        fill="white"
      />
      <path
        d="M19.4384 8.92269L15.401 9.63469L13.321 5.85425C13.0917 5.43736 12.6206 5.21425 12.1548 5.29603C11.6864 5.37869 11.3219 5.75025 11.249 6.21958L10.5868 10.4836L6.54949 11.1956C6.07393 11.2791 5.70682 11.6622 5.64193 12.1414C5.57704 12.6205 5.82949 13.0862 6.26682 13.2942L9.8846 15.0116L9.21971 19.2889C9.14593 19.7654 9.38771 20.2347 9.81793 20.4525C9.97705 20.5325 10.1477 20.5707 10.3175 20.5707C10.6082 20.5707 10.8953 20.4569 11.1095 20.24L14.2313 17.0747L18.2473 18.9814C18.6828 19.1876 19.2019 19.0907 19.5326 18.7396C19.8633 18.3885 19.929 17.8649 19.697 17.4427L17.6099 13.6489L20.4233 10.7974C20.7628 10.4534 20.8402 9.92892 20.6162 9.50047C20.3904 9.07203 19.9113 8.84003 19.4384 8.92269ZM23.5593 8.92269L27.5966 9.63469L29.6766 5.85425C29.9059 5.43736 30.377 5.21425 30.8428 5.29603C31.3104 5.37869 31.6748 5.75025 31.7477 6.21958L32.4099 10.4836L36.4473 11.1956C36.9237 11.2791 37.2908 11.6614 37.3548 12.1405C37.4188 12.6196 37.1673 13.0854 36.7299 13.2934L33.1122 15.0107L33.777 19.288C33.8508 19.7645 33.609 20.2338 33.1788 20.4516C33.0197 20.5316 32.849 20.5698 32.6793 20.5698C32.3886 20.5698 32.1015 20.456 31.8873 20.2391L28.7655 17.0738L24.7495 18.9805C24.3139 19.1867 23.7948 19.0898 23.4642 18.7387C23.1335 18.3876 23.0677 17.864 23.2997 17.4418L25.3868 13.6489L22.5735 10.7974C22.2339 10.4534 22.1566 9.92892 22.3806 9.50047C22.6073 9.07203 23.0864 8.84003 23.5593 8.92269Z"
        fill="#E95F28"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(5.5 5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSmileRating5',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
