<template>
  <svg
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M37.5 21C37.5 29.8364 30.3364 37 21.5 37C12.6644 37 5.5 29.8364 5.5 21C5.5 12.1644 12.6644 5 21.5 5C30.3364 5 37.5 12.1644 37.5 21Z"
        fill="#FFCC4D"
      />
      <path
        d="M15.7222 21.0001C16.9495 21.0001 17.9444 19.6072 17.9444 17.8889C17.9444 16.1707 16.9495 14.7778 15.7222 14.7778C14.4949 14.7778 13.5 16.1707 13.5 17.8889C13.5 19.6072 14.4949 21.0001 15.7222 21.0001Z"
        fill="#664500"
      />
      <path
        d="M27.2779 21.0001C28.5052 21.0001 29.5001 19.6072 29.5001 17.8889C29.5001 16.1707 28.5052 14.7778 27.2779 14.7778C26.0506 14.7778 25.0557 16.1707 25.0557 17.8889C25.0557 19.6072 26.0506 21.0001 27.2779 21.0001Z"
        fill="#664500"
      />
      <path
        d="M13.2026 29.7743C13.3609 29.9174 13.5973 29.9263 13.7671 29.8001C13.8018 29.7743 17.2533 27.2223 21.5004 27.2223C25.7369 27.2223 29.2 29.7743 29.2338 29.8001C29.4035 29.9263 29.64 29.9157 29.7982 29.7743C29.9555 29.6321 29.9911 29.3974 29.8818 29.2161C29.7671 29.0259 27.0222 24.5557 21.5004 24.5557C15.9786 24.5557 13.2329 29.025 13.1191 29.2161C13.0098 29.3983 13.0444 29.6321 13.2026 29.7743Z"
        fill="#664500"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(5.5 5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSmileRating2',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
