<template>
  <svg
    width="43"
    height="42"
    viewBox="0 0 43 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M37.5 21C37.5 29.8364 30.3364 37 21.5 37C12.6644 37 5.5 29.8364 5.5 21C5.5 12.1644 12.6644 5 21.5 5C30.3364 5 37.5 12.1644 37.5 21Z"
        fill="#FFCC4D"
      />
      <path
        d="M15.7222 22.7779C16.9495 22.7779 17.9444 21.385 17.9444 19.6668C17.9444 17.9486 16.9495 16.5557 15.7222 16.5557C14.4949 16.5557 13.5 17.9486 13.5 19.6668C13.5 21.385 14.4949 22.7779 15.7222 22.7779Z"
        fill="#664500"
      />
      <path
        d="M27.2779 22.7779C28.5052 22.7779 29.5001 21.385 29.5001 19.6668C29.5001 17.9486 28.5052 16.5557 27.2779 16.5557C26.0506 16.5557 25.0557 17.9486 25.0557 19.6668C25.0557 21.385 26.0506 22.7779 27.2779 22.7779Z"
        fill="#664500"
      />
      <path
        d="M27.722 28.1113H15.2776C14.7869 28.1113 14.3887 27.7139 14.3887 27.2224C14.3887 26.7308 14.7869 26.3335 15.2776 26.3335H27.722C28.2136 26.3335 28.6109 26.7308 28.6109 27.2224C28.6109 27.7139 28.2136 28.1113 27.722 28.1113Z"
        fill="#664500"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(5.5 5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconSmileRating3',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
