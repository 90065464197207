<style lang="scss"></style>

<template>
  <level-block-base :complete="true" :level="1">
    <template #left></template>
    <template #right>
      <div class="pt-4 md:pt-6 px-6 md:px-8 pb-6">
        <digital-time-value v-if="firstLevelResult" :value="firstLevelResult" />
      </div>
    </template>
  </level-block-base>
</template>

<script>
import LevelBlockBase from './LevelBlockBase.vue';
import DigitalTimeValue from './UI/DigitalTimeValue.vue';

export default {
  name: 'LevelBlockFirst',
  components: { LevelBlockBase, DigitalTimeValue },
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {
    firstLevelResult() {
      return this.$store.state.game.firstLevelResult;
    },
  },
};
</script>
